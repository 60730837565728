import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SocialSharing from '../components/SocialSharing'
import Img from 'gatsby-image'
import parse from 'html-react-parser'

export class ProjectTemplate extends React.Component {
  state = {
    ogimg: '',
  }

  socialImageOver = img => {
    this.setState({ ogimg: img })
  }

  render() {
    const {
      title,
      postslug,
      quote,
      category,
      gallerij,
      fotocredits,
      fotocreditslink,
      freetext,
      previous,
      next,
      base,
      sitename,
    } = this.props

    return (
      <section className="section single-project">
        <Helmet>
          <meta name="og:image" content={this.state.ogimg} />
          <meta name="twitter:card" content="photo" />
          <meta name="twitter:url" content={postslug} />
          <meta name="twitter:image" content={this.state.ogimg} />
        </Helmet>
        <div className="container content">
          <div className="columns is-mobile is-centered">
            <div className="column is-12-mobile is-10-tablet is-8-desktop">
              <nav className="columns prevnext top is-uppercase is-mobile">
                <div className="column">
                  {previous ? (
                    <a href={`/projecten/${previous.slug}`}>Vorige</a>
                  ) : null}
                </div>
                <div className="column is-half has-text-centered">
                  <h1 className="title is-inline-block">
                    <span className="project-title">{title}</span>
                    <span className="project-cat">{category}</span>
                  </h1>
                </div>
                <div className="column has-text-right">
                  {next ? (
                    <a href={`/projecten/${next.slug}`}>Volgende</a>
                  ) : null}
                </div>
              </nav>
            </div>
          </div>

          <div className="columns is-mobile is-centered">
            <div className="column is-10-mobile is-10-tablet is-8-desktop">
              {quote ? (
                <div className="columns is-mobile is-centered quote-wrap">
                  <div className="column is-full-mobile is-10-tablet">
                    <div className="quote has-text-centered">
                      {parse(quote)}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="columns is-mobile is-centered">
            <div className="column is-10-mobile is-10-tablet is-8-desktop">
              <div className="gallery">
                {gallerij
                  ? gallerij.map(item => {
                      if (item.foto_afmeting == 'FW') {
                        return (
                          <div className="columns is-mobile" key={item.foto.id}>
                            <div className="column is-12 social-share-img">
                              {item.foto.localFile ? (
                                <>
                                  <Img
                                    fluid={
                                      item.foto.localFile.childImageSharp.fluid
                                    }
                                    alt={item.foto.alt_text}
                                    title={item.foto.title}
                                  />
                                  <div
                                    className="over-area"
                                    onMouseOver={() =>
                                      this.socialImageOver(
                                        base +
                                          item.foto.localFile.childImageSharp
                                            .fluid.src
                                      )
                                    }
                                  >
                                    <SocialSharing
                                      url={postslug}
                                      width="half-width"
                                      text={`${title} | ${sitename}`}
                                      fotocredits={fotocredits}
                                      base={base}
                                      imgUrl={
                                        item.foto.localFile.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        )
                      } else if (item.foto_afmeting == 'LR') {
                        return (
                          <div className="columns is-mobile" key={item.foto.id}>
                            <div className="column is-6 social-share-img">
                              {item.foto.localFile ? (
                                <>
                                  <Img
                                    fluid={
                                      item.foto.localFile.childImageSharp.fluid
                                    }
                                    alt={item.foto.alt_text}
                                    title={item.foto.title}
                                  />
                                  <div
                                    className="over-area"
                                    onMouseOver={() =>
                                      this.socialImageOver(
                                        base +
                                          item.foto.localFile.childImageSharp
                                            .fluid.src
                                      )
                                    }
                                  >
                                    <SocialSharing
                                      url={postslug}
                                      width="half-width"
                                      text={`${title} | ${sitename}`}
                                      fotocredits={fotocredits}
                                      base={base}
                                      imgUrl={
                                        item.foto.localFile.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>

                            <div className="column is-6 social-share-img">
                              {item.foto_twee.localFile ? (
                                <>
                                  <Img
                                    fluid={
                                      item.foto_twee.localFile.childImageSharp
                                        .fluid
                                    }
                                    alt={item.foto_twee.alt}
                                    title={item.foto_twee.title}
                                  />
                                  <div
                                    className="over-area"
                                    onMouseOver={() =>
                                      this.socialImageOver(
                                        base +
                                          item.foto_twee.localFile
                                            .childImageSharp.fluid.src
                                      )
                                    }
                                  >
                                    <SocialSharing
                                      url={postslug}
                                      width="half-width"
                                      text={`${title} | ${sitename}`}
                                      fotocredits={fotocredits}
                                      base={base}
                                      imgUrl={
                                        item.foto.localFile.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        )
                      } else if (item.foto_afmeting == 'HL') {
                        return (
                          <div className="columns is-mobile" key={item.foto.id}>
                            <div className="column is-6 social-share-img ">
                              {item.foto.localFile ? (
                                <>
                                  <Img
                                    fluid={
                                      item.foto.localFile.childImageSharp.fluid
                                    }
                                    alt={item.foto.alt_text}
                                    title={item.foto.title}
                                  />
                                  <div
                                    className="over-area"
                                    onMouseOver={() =>
                                      this.socialImageOver(
                                        base +
                                          item.foto.localFile.childImageSharp
                                            .fluid.src
                                      )
                                    }
                                  >
                                    <SocialSharing
                                      url={postslug}
                                      width="half-width"
                                      text={`${title} | ${sitename}`}
                                      fotocredits={fotocredits}
                                      base={base}
                                      imgUrl={
                                        item.foto.localFile.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <div className="column is-6"></div>
                          </div>
                        )
                      } else if (item.foto_afmeting == 'HR') {
                        return (
                          <div className="columns is-mobile" key={item.foto.id}>
                            <div className="column is-6"></div>
                            <div className="column is-6 social-share-img">
                              {item.foto.localFile ? (
                                <>
                                  <Img
                                    fluid={
                                      item.foto.localFile.childImageSharp.fluid
                                    }
                                    alt={item.foto.alt_text}
                                    title={item.foto.title}
                                  />
                                  <div
                                    className="over-area"
                                    onMouseOver={() =>
                                      base +
                                      this.socialImageOver(
                                        item.foto.localFile.childImageSharp
                                          .fluid.src
                                      )
                                    }
                                  >
                                    <SocialSharing
                                      url={postslug}
                                      width="half-width"
                                      text={`${title} | ${sitename}`}
                                      fotocredits={fotocredits}
                                      base={base}
                                      imgUrl={
                                        item.foto.localFile.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        )
                      }
                    })
                  : null}
                <div className="foto-credits has-text-centered is-uppercase">
                  <a href={fotocreditslink} target="_blank">
                    {fotocredits}
                  </a>
                </div>

                {freetext ? (
                  <div className="free-text has-text-centered">{freetext}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="columns is-mobile is-centered">
            <div className="column is-12-mobile is-10-tablet is-8-desktop">
              <nav className="columns prevnext under is-uppercase is-mobile">
                <div className="column">
                  {previous ? (
                    <a href={`/projecten/${previous.slug}`}>Vorige</a>
                  ) : null}
                </div>
                <div className="column is-half has-text-centered">
                  <Link
                    to="/projecten/"
                    className="project-link is-inline-block"
                  >
                    Projecten
                  </Link>
                </div>
                <div className="column has-text-right">
                  {next ? (
                    <a href={`/projecten/${next.slug}`}>Volgende</a>
                  ) : null}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ProjectTemplate.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  previous: PropTypes.object,
  next: PropTypes.object,
}

const Project = ({ data, pageContext }) => {
  const { wordpressWpProject: post } = data
  const { siteUrl: base, title: sitename } = data.site.siteMetadata
  const { previous, next } = pageContext

  return (
    <Layout>
      <Helmet>
        <title>{`${post.title} | ${sitename}`}</title>
        <meta name="description" content={post.acf.meta_description} />
      </Helmet>

      <ProjectTemplate
        title={post.title}
        slug={post.slug}
        postslug={`${base}/projecten/${post.slug}`}
        quote={post.acf.quote}
        gallerij={post.acf.gallerij}
        category={post.categories[0].name}
        fotocredits={post.acf.foto_credits}
        fotocreditslink={post.acf.foto_credits_link}
        freetext={post.acf.free_text}
        previous={previous}
        next={next}
        base={base}
        sitename={sitename}
      />
    </Layout>
  )
}

Project.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.object,
}

export default Project

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_project {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }

  query ProjectByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    wordpressWpProject(id: { eq: $id }) {
      id
      title
      categories {
        name
      }
      slug
      acf {
        meta_description
        meta_tags
        foto_credits
        foto_credits_link
        free_text
        quote
        gallerij {
          foto_afmeting
          foto {
            id
            alt_text
            title
            localFile {
              absolutePath
              childImageSharp {
                fluid(maxWidth: 920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          foto_twee {
            alt_text
            title
            localFile {
              absolutePath
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
