import React from 'react'
import {
  FaRegEnvelope,
  FaFacebookF,
  FaPinterest,
  FaTwitter,
} from 'react-icons/fa'

import { IconContext } from 'react-icons'

import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share'

const iconStyles = {
  color: '#444',
  className: 'social-share-icon',
}

const SocialSharing = props => {
  const { url, imgUrl, base, text, fotocredits, width } = props

  return (
    <div className={`social-share-overlay ${width}`}>
      <div className="social-mobile-split">
        <EmailShareButton url={url} subject={text} body={text}>
          <IconContext.Provider value={iconStyles}>
            <FaRegEnvelope />
          </IconContext.Provider>
        </EmailShareButton>
        <FacebookShareButton url={url} quote={fotocredits}>
          <IconContext.Provider value={iconStyles}>
            <FaFacebookF />
          </IconContext.Provider>
        </FacebookShareButton>
      </div>
      <div className="social-mobile-split">
        <PinterestShareButton
          url={url}
          description={`${text} | ${fotocredits}`}
          media={base + imgUrl}
        >
          <IconContext.Provider value={iconStyles}>
            <FaPinterest />
          </IconContext.Provider>
        </PinterestShareButton>
        <TwitterShareButton url={url} title={text}>
          <IconContext.Provider value={iconStyles}>
            <FaTwitter />
          </IconContext.Provider>
        </TwitterShareButton>
      </div>
    </div>
  )
}

export default SocialSharing
